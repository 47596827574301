<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>

		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>

		<v-checkbox label="Richieste Verificate e da assegnare" v-model="filterVerifiedNotAssigned" @change="LoadWebhooks(true)"></v-checkbox>

		<skeleton-table :columns="3" :actions="0" v-if="LoadingData"></skeleton-table>
		<div v-else>
			<div v-for="item in items" :key="item.WebhookId">
				<TallyRequestCard :item="item" @userCreated="LoadWebhooks">
				</TallyRequestCard>
			</div>
		</div>
		<div class="text-center">
			<v-pagination
				v-if="itemsPaging"
				v-model="currentPage"
				:length="itemsPaging.TotalPages"
				:total-visible="5"
				:disabled="LoadingData"
			></v-pagination>
		</div>
	</div>
</template>

<script>
import CrudClient from "@/services/CrudClient/";
import TallyRequestCard from "./components/TallyRequestCard.vue";

import { mapActions } from "vuex";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},
	components: {
		TallyRequestCard,
	},
	data() {
		return {
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 10,

			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: "Tally",
					disabled: false,
					exact: true,
					to: "/Admin/TallyWebhooks",
				},
			],
			filterVerifiedNotAssigned: false
		};
	},
	watch: {
		async currentPage() {
			await this.LoadWebhooks();
		},
	},
	created() {
		this.TallyWebhookService = new CrudClient("TallyWebhook");
	},
	async mounted() {
		await this.LoadWebhooks();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		// async OnUserCreated

		async LoadWebhooks(resetPagination = false) {
			try {
				this.LoadingData = true;

				if (resetPagination) {
					this.currentPage = 1;
				}

				let filter;
				if (this.filterVerifiedNotAssigned) {
					filter = "EmailVerified:true;ApplicationUser.Patient.DefaultNutritionistUserId:isnull;IgnoreRequest:false"
				}

				const res = await this.TallyWebhookService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*, ApplicationUser.Patient.*",
					filter: filter,
					orderBy: "WebhookId:desc",
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;

				this.items.forEach((element) => {
					try {
						const webhookData = JSON.parse(element.Data);
						element.webhookData = webhookData;
						// name is the first field
						element.Name = webhookData.data.fields.at(0).value;

						// email is tha last field
						// element.Email = webhookData.data.fields.at(-1).value;
						element.Email = webhookData.data.fields.findLast(
							(d) => d.type === "INPUT_EMAIL"
						).value;
					} catch (errorJson) {
						this.$log.error(errorJson);
						this.$captureError(errorJson);
						element.webhookData = null;
						element.Name = "Unable to parse data from JSON";
						element.Email = "Unable to parse data from JSON";
					}
				});
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>

<style>
</style>
<i18n>
{
	"it":{
		"pageTitle": "Nuove Richieste"
	}
}
</i18n>
