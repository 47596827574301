<template>
	<v-card class="ma-4 pa-4">
		<v-card-title>Date non disponibili</v-card-title>
		<v-card-text>
		<ConfirmTool ref="confirm"></ConfirmTool>

		<v-alert type="info" v-if="items && items.length === 0">Non ci sono date non disponibili</v-alert>
		<!-- <h3 class="nutriyou-h3"></h3> -->
		<v-list>
			<v-list-item v-for="item in items" :key="item.UnavailableDatesId">
				<v-list-item-title>	{{ item.StartDate | formatDate }} - {{ item.EndDate | formatDate }}</v-list-item-title>
				<v-list-item-action>
					<v-btn icon color="error" @click="DeleteDates(item.UnavailableDatesId)">
						<v-icon>fas fa-trash</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</v-list>
		<v-divider class="my-4"></v-divider>
		</v-card-text>
			<SimpleFormCard :cardProps="{flat:true}"
				ref="formNutritionistUnavailableDates"
				:formContent="formNutritionistUnavailableDates"
				:metadata="metadata"
				:metadataLoaded="metadataLoaded"
				@submitForm="SaveDates"
				:showCancelButton="false"
				:locali18n="$i18n"
			></SimpleFormCard>
	</v-card>
</template>
<script>

import { mapActions } from "vuex";
import CrudFormData from "@/utilities/CrudFormData.js";
import CrudClient from "@/services/CrudClient/";
import metadata from "@/mixins/metadata";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import ConfirmTool from "@/components/Shared/Common/Confirm";

export default {
	mixins: [metadata],
	components: {
		SimpleFormCard,
		ConfirmTool
	},
	data() {
		return {
			formNutritionistUnavailableDates: new CrudFormData("formNutritionistUnavailableDates", [
				{
					FieldName: "StartDate",
					type: "DatePicker",
				},
				{
					FieldName: "EndDate",
					type: "DatePicker",
				}]),

			items: null,
			LoadingData: false
		}
	},

	props: {
		NutritionistUserId: { type: String, required: true },
	},

	created() {
		// add services to this
		this.NutritionistsUnavailableDatesService = new CrudClient("NutritionistUnavailableDates");
	},
	async mounted() {
		await this.LoadMetadata(this.NutritionistsUnavailableDatesService);

		await this.LoadUnavailableDates();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async SaveDates(dto) {
			try {
				this.formNutritionistUnavailableDates.FormLoading = true;

				dto.UserId = this.NutritionistUserId;
				await this.NutritionistsUnavailableDatesService.Post(null, dto, false);

				if (this.$refs.formNutritionistUnavailableDates) this.$refs.formNutritionistUnavailableDates.ResetForm();

				await this.LoadUnavailableDates();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.formNutritionistUnavailableDates.FormLoading = false;
			}
		},

		async DeleteDates(UnavailableDatesId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("DeleteDates"),
					"Stai per cancellare l'intervallo",
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.NutritionistsUnavailableDatesService.Delete(UnavailableDatesId);
				await this.LoadUnavailableDates();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteData") });
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadUnavailableDates() {
			this.LoadingData = true;
			try {
				const res = await this.NutritionistsUnavailableDatesService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "UnavailableDatesId, UserId, StartDate, EndDate",
					orderBy: "StartDate",
					filter: "UserId:" + this.NutritionistUserId,
				});
				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},
	}
}
</script>
<i18n>
	{
		"it":{
			"DeleteDates": "Cancella le date non disponibili",
			"formNutritionistUnavailableDates": {
				"StartDate": "Data inizio",
				"EndDate": "Data fine"
			}
		}
	}
</i18n>
