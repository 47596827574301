<template>
	<v-autocomplete
		:label="$attrs.label || $t('patientname')"
		:items="Users"
		:loading="LoadingUsers"
		item-text="DisplayName"
		item-value="UserId"
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="$emit('input', value)"
		:search-input.sync="search"
		clearable
	></v-autocomplete>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			LoadingUsers: false,
			Users: [],
			search: null,
		};
	},
	props: {
		value: { type: Number, default: null },
		IsAdmin: { type: Boolean, default: false }
		// addEmptyItem: { type: Boolean, required: false, default: false },
	},
	created() {
		this.PatientsService = new CrudClient(this.IsAdmin ? "Patients" : "CurrentNutritionist/Patients");
	},
	watch: {
		async search(val) {
			this.$log.debug("Watch search: " + val);
			val && val !== this.value && val.length > 0 && (await this.LoadUsers(val));
		},

		// value: {
		// 	immediate: true,
		// 	handler: async function (newVal) {
		// 		if (!newVal) return;
		// 		// Programmatic value set
		// 		if (!this.Users.find((i) => i.EventId === newVal)) {
		// 			const res = await this.ApplicationUsersService.GetSelectedFields(
		// 				newVal,
		// 				"AspNetUser.UserProfile.DisplayName, UserId"
		// 			);

		// 			this.search = res.Name;
		// 		}
		// 	},
		// },
	},
	async mounted() {
	},
	methods: {
		...mapActions(["snackError"]),

		async LoadUsers(val) {
			try {
				this.LoadingUsers = true;

				this.Users = await this.PatientsService.Get(`Search?userInput=${val}&limit=10`);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingUsers = false;
			}
		},
	},
};
</script>
<i18n>
	{
		"it":{
			"patientname": "Nome paziente"
		}
	}
</i18n>
